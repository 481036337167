.container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	padding: 0 32px 48px;

	@media screen and (max-width: 767px) {
		min-width: 320px;
	}

	.currencyHeader {
		font-weight: 600;
		font-size: 22px;
		line-height: 27px;
	}

	.currencyBody {
		display: flex;
		align-items: center;
		gap: 8px;

		& > img {
			width: 24px !important;
			height: 24px !important;
		}

		@media screen and (max-width: 767px) {
			flex-direction: column;
			align-items: flex-start;
		}
	}
}
